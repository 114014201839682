import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Home, Flame, ShoppingCart, Package, UserPlus, LogIn, LogOut } from 'lucide-react';
import './NavBar.css';
import { ReactComponent as Logo } from '../assets/logo.svg';

const NavBar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    delete axios.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
    setUsername('');
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const storedUsername = localStorage.getItem('username');

      if (accessToken && storedUsername) {
        setIsLoggedIn(true);
        setUsername(storedUsername);
      } else if (accessToken) {
        try {
          const headers = { 'Authorization': `Bearer ${accessToken}` };
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/`, { headers });
          setIsLoggedIn(true);
          setUsername(response.data.username);
          localStorage.setItem('username', response.data.username);
        } catch (error) {
          handleLogout();
        }
      }
    };

    checkAuthStatus();
  }, [handleLogout]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <Logo className="logo-svg" />
        </Link>
        
        <div className="navbar-links">
          <Link to="/" className="navbar-link" title="Inicio">
            <Home size={28} />
          </Link>
          <Link to="/pulls" className="navbar-link" title="Pulls">
            <Flame size={28} />
          </Link>
          <Link to="/carrito" className="navbar-link" title="Carrito">
            <ShoppingCart size={28} />
          </Link>
          <Link to="/mis-pedidos" className="navbar-link" title="Pedidos">
            <Package size={28} />
          </Link>
        </div>

        <div className="navbar-auth">
          {isLoggedIn ? (
            <>
              <span className="navbar-username">{username}</span>
              <button onClick={handleLogout} className="navbar-button" title="Salir">
                <LogOut size={24} />
              </button>
            </>
          ) : (
            <>
              <button onClick={() => navigate('/registro')} className="navbar-button" title="Registrarse">
                <UserPlus size={24} />
              </button>
              <button onClick={() => navigate('/login')} className="navbar-button" title="Login">
                <LogIn size={24} />
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;