import React, { useState, useEffect, useContext, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import KRGlue from '@lyracom/embedded-form-glue';
import Layout from './components/Layout';
import PullsList from './components/PullsList';
import CarritoCompra from './components/CarritoCompra';
import MisPedidos from './components/MisPedidos';
import './global.css';
import LandingPage from './components/LandingPage';
import LandingPage2 from './components/LandingPage2';
import Administration from './components/Administration';
import RegistroUsuario from './components/RegistroUsuario';
import LoginUsuario from './components/LoginUsuario';
import DireccionEnvio from './components/DireccionEnvio';
import setupInterceptors from './utils/axiosInterceptor';

export const AuthContext = React.createContext(null);
export const PaymentContext = React.createContext(null);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = React.useContext(AuthContext);
  
  if (isLoading) {
    return <div>Cargando...</div>;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  return children;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [publicKey, setPublicKey] = useState("48322021:testpublickey_ibyTrhlpfzDZWZ4gEnZ8vP9JsgasjUgjtAhy8GMPtIQ6u");
  const [endPoint, setEndPoint] = useState("https://api.micuentaweb.pe");

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem('accessToken');
    console.log('Token present:', !!token);
    if (token) {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error verificando token:', error);
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setupInterceptors(logout);
    checkAuthStatus();
    
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, [checkAuthStatus]);

  const login = useCallback((token, refreshToken) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsAuthenticated(true);
    setIsLoading(false);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setIsLoading(false);
  }, []);

  const getFormToken = useCallback(async (amount, orderLines) => {
    try {
      // Tu backend ya maneja la creación del payment y la obtención del form token
      const { data } = await axios.post('/create-payment/', {
        amount: amount*100,
        currency: "PEN",
        orderLines: orderLines
      });
      
      // Tu backend ya devuelve el formToken y la public key
      return data.formToken;
    } catch (error) {
      console.error('Error getting form token:', error);
      throw error;
    }
  }, []);

  const validatePayment = useCallback(async (paymentData) => {
    try {
      const { data } = await axios.post('/validate-payment/', {
        'kr-answer': paymentData.clientAnswer,
        'kr-hash': paymentData.hash
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return data.isPaid;
    } catch (error) {
      console.error('Error validating payment:', error);
      throw error;
    }
  }, []);

  const authContextValue = React.useMemo(() => ({
    isAuthenticated,
    isLoading,
    login,
    logout,
    checkAuthStatus
  }), [isAuthenticated, isLoading, login, logout, checkAuthStatus]);

  const paymentContextValue = React.useMemo(() => ({
    publicKey,
    endPoint,
    getFormToken,
    validatePayment
  }), [publicKey, endPoint, getFormToken, validatePayment]);

  return (
    <AuthContext.Provider value={authContextValue}>
      <PaymentContext.Provider value={paymentContextValue}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<LandingPage2 />} />
              <Route path="/registro" element={<RegistroUsuario />} />
              <Route path="/login" element={<LoginUsuario />} />
              <Route path="/pulls" element={<PullsList />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/landing2" element={<LandingPage2 />} />
              <Route path="/admin" element={<Administration/>} />

              <Route path="/carrito" element={
                <ProtectedRoute>
                  <CarritoCompra />
                </ProtectedRoute>
              } />
              <Route path="/mis-pedidos" element={
                <ProtectedRoute>
                  <MisPedidos />
                </ProtectedRoute>
              } />
              <Route path="/direccion" element={
                <ProtectedRoute>
                  <DireccionEnvio />
                </ProtectedRoute>
              } />
            </Routes>
          </Layout>
        </Router>
      </PaymentContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;