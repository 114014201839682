import FAQ from './Faq';
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, Shield, Clock, Check, ArrowRight, MessageCircle,ChevronDown, CreditCard  } from 'lucide-react';
import AuthButtons from './AuthButtons';
import { Link } from 'react-router-dom';


const THEME = {
  themeprimary: '#0095f6',
  background: '#0a0a0a',
  cardBg: '#111111',
  textPrimary: '#EEEEEE',
  textSecondary: '#EEEEEE',
  border: '#343535'
};

const FEATURES = [
  {
    icon: <Users size={24} />,
    title: "Únete a un Grupo",
    subtitle: "Comienza a Ahorrar",
    description: "Encuentra el producto que te interesa y únete a un grupo de compra.",
    benefits: ["Sin compromiso inicial", "Monitorea el precio en tiempo real", "Decide cuando el precio te conviene"],
    media: {
      url: "/api/placeholder/400/300"
    }
  },
  {
    icon: <Clock size={24} />,
    title: "Espera y Ahorra",
    subtitle: "El Precio Baja",
    description: "Observa cómo el precio disminuye mientras más personas se unen al grupo.",
    steps: [
      { number: "01", text: "Monitorea el progreso" },
      { number: "02", text: "Recibe actualizaciones" },
      { number: "03", text: "Decide cuándo comprar" }
    ],
    media: {
      url: "/api/placeholder/400/300"
    }
  },
  {
    icon: <Shield size={24} />,
    title: "Compra Seguro",
    subtitle: "100% Garantizado",
    description: "Realiza tu compra con total seguridad cuando alcances el precio deseado.",
    guarantees: [
      "Pago seguro garantizado",
      "Envío protegido",
      "Soporte dedicado"
    ],
    media: {
      url: "/api/placeholder/400/300"
    }
  }
];

const HeroBanner = ({ isLoggedIn, username, setIsLoggedIn }) => {
  const scrollToFAQ = () => {
    document.getElementById('faq-section')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative">


      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative min-h-screen flex items-center justify-center text-center px-4"
      >
        <div className="max-w-4xl">
          <motion.h1 
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="text-4xl md:text-7xl font-bold mb-6"
            style={{ color: THEME.textPrimary }}
          >
            Compremos Juntos,{' '}
            <span style={{ color: THEME.themeprimary }}>Paguemos Menos</span>
          </motion.h1>
          
          <p className="text-lg md:text-2xl mb-8" style={{ color: THEME.textSecondary }}>
            El poder de la compra grupal en tus manos
          </p>

          <div className="flex flex-col md:flex-row gap-4 justify-center mb-8">
          <Link to="/pulls">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-6 py-3 rounded-lg text-lg font-medium transition-colors flex items-center justify-center gap-2"
            style={{ 
              backgroundColor: THEME.themeprimary,
              color: THEME.textPrimary
            }}
            >
              Ver Grupos Activos <ArrowRight size={20} />
            </motion.button>
          </Link>
            
            <motion.button
              onClick={scrollToFAQ}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-6 py-3 rounded-lg text-lg font-medium transition-colors"
              style={{ 
                border: `1px solid ${THEME.themeprimary}`,
                color: THEME.themeprimary
              }}
            >
              ¿Cómo Funciona?
            </motion.button>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="flex justify-center gap-8"
            style={{ color: THEME.textSecondary }}
          >
            <div>
              <div className="text-2xl font-bold" style={{ color: THEME.themeprimary }}>15+</div>
              <div className="text-sm">Grupos Activos</div>
            </div>
            <div>
              <div className="text-2xl font-bold" style={{ color: THEME.themeprimary }}>35%</div>
              <div className="text-sm">Ahorro Promedio</div>
            </div>
            <div>
              <div className="text-2xl font-bold" style={{ color: THEME.themeprimary }}>100%</div>
              <div className="text-sm">Satisfacción</div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title, subtitle, description, benefits, steps, guarantees, media }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="relative p-6 rounded-xl overflow-hidden"
    style={{ 
      backgroundColor: THEME.cardBg,
      border: `1px solid ${THEME.border}`,
      borderRadius: '20px'
    }}
  >
    <div className="relative z-10 space-y-4">

      
      <div className="w-12 h-12 rounded-lg flex items-center justify-center" 
        style={{ 
          backgroundColor: `${THEME.themeprimary}20`,
          color: THEME.themeprimary 
        }}>
        {icon}
      </div>
      
      <h3 className="text-xl font-bold" style={{ color: THEME.textPrimary }}>{title}</h3>
      <h4 className="text-lg" style={{ color: THEME.themeprimary }}>{subtitle}</h4>
      <p style={{ color: THEME.textSecondary }}>{description}</p>
      
      {benefits && (
        <ul className="space-y-2">
          {benefits.map((benefit, index) => (
            <li key={index} className="flex items-center gap-2" style={{ color: THEME.textSecondary }}>
              <Check size={16} style={{ color: THEME.themeprimary }} />
              {benefit}
            </li>
          ))}
        </ul>
      )}
      
      {steps && (
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center gap-4">
              <span style={{ color: THEME.themeprimary }} className="font-bold">{step.number}</span>
              <span style={{ color: THEME.textSecondary }}>{step.text}</span>
            </div>
          ))}
        </div>
      )}
      
      {guarantees && (
        <ul className="space-y-2">
          {guarantees.map((guarantee, index) => (
            <li key={index} className="flex items-center gap-2" style={{ color: THEME.textSecondary }}>
              <Shield size={16} style={{ color: THEME.themeprimary }} />
              {guarantee}
            </li>
          ))}
        </ul>
      )}
    </div>
  </motion.div>
);



const FinalCTA = () => (
  <section className="py-16 px-4" style={{ backgroundColor: THEME.themeprimary }}>
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-3xl font-bold mb-4" style={{ color: THEME.textPrimary }}>
        Únete Hoy y Comienza a Ahorrar
      </h2>
      <p className="text-lg mb-8" style={{ color: THEME.textPrimary }}>
        Aprovecha el poder de la compra grupal y accede a precios increíbles.
      </p>
      <Link to="/pulls">

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="px-8 py-3 rounded-lg text-lg font-medium transition-colors"
        style={{ 
          backgroundColor: THEME.background,
          color: THEME.textPrimary
        }}
      >
        Ver Grupos Activos
      </motion.button>
      </Link>

    </div>
  </section>
);

const LandingPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  return (
    <div style={{ 
      minHeight: '100vh',
      backgroundColor: THEME.background
    }}>
      <HeroBanner 
        isLoggedIn={isLoggedIn} 
        username={username}
        setIsLoggedIn={setIsLoggedIn} 
      />
      
      <section className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center" style={{ color: THEME.textPrimary }}>
          Comprar en Grupo Nunca Fue Tan Fácil
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {FEATURES.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </section>

      <FAQ />
      <FinalCTA />
    </div>
  );
};

export default LandingPage;