import React from 'react';
import { motion } from 'framer-motion';
import NavBar from './NavBar';

const BackgroundShapes = () => (
  <div className="fixed inset-0 overflow-hidden pointer-events-none">
    {['#00ADB5', '#393E46', '#EEEEEE'].map((color, i) => (
      <motion.div
        key={i}
        className="absolute"
        style={{
          top: `${i * 30}%`,
          left: `${i * 20}%`,
          width: '40vw',
          height: '40vw',
          border: `2px solid ${color}`,
          borderRadius: i % 2 ? '30% 70% 70% 30% / 30% 30% 70% 70%' : '64% 36% 27% 73% / 55% 58% 42% 45%',
          opacity: 0.1,
          filter: 'blur(1px)',
        }}
        animate={{
          rotate: 360,
          scale: [1, 1.1, 1],
          x: [0, 50, 0],
          y: [0, 30, 0],
        }}
        transition={{
          duration: 20 + i * 5,
          repeat: Infinity,
          ease: "linear"
        }}
      />
    ))}
  </div>
);

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-[#0a0a0a]">
      <NavBar />
      <main className="flex-grow pl-0 md:pl-[72px] pb-[60px] md:pb-8 pt-0 md:pt-0 relative z-10">
        {children}
      </main>
    </div>
  );
};

export default Layout;