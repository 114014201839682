import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../App';

const LoginUsuario = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/`, formData);
      if (response.data.access) {
        login(response.data.access, response.data.refresh);
        
        try {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
          localStorage.setItem('username', userResponse.data.username);
        } catch (userError) {
          console.error('Error fetching user data:', userError);
        }

        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.detail || 'Ocurrió un error durante el inicio de sesión');
    }
  };

  return (
    <div className="page-container">
      <h2 className="page-title">Iniciar Sesión</h2>
      <div className="card">
        <form onSubmit={handleSubmit} className="auth-form">
          {error && <p className="auth-error">{error}</p>}
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="username">Nombre de usuario</label>
            <input
              className="auth-input"
              id="username"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="password">Contraseña</label>
            <input
              className="auth-input"
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex gap-4">
            <button className="btn" type="submit">
              Iniciar Sesión
            </button>
            <Link to="/registro" className="btn">
              Registrarse
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginUsuario;