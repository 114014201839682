import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const DireccionEnvio = ({ onDireccionSeleccionada }) => {
  const [direcciones, setDirecciones] = useState([]);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState('');
  const [mostrarNuevaDireccion, setMostrarNuevaDireccion] = useState(false);
  const [nuevaDireccion, setNuevaDireccion] = useState({
    calle: '',
    numero: '',
    referencia: '',
    departamento: '',
    provincia: '',
    distrito: ''
  });
  const [ubigeos, setUbigeos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);

  const cargarDatos = useCallback(async () => {
    try {
      const [{ data: direccionesData }, { data: ubigeosData }] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/direcciones/`),
        axios.get(`${process.env.REACT_APP_API_URL}/ubigeos/`)
      ]);
      setDirecciones(direccionesData);
      setUbigeos(ubigeosData);
      setDepartamentos([...new Set(ubigeosData.map(u => u.departamento))]);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    }
  }, []);

  useEffect(() => {
    cargarDatos();
  }, [cargarDatos]);

  const handleNuevaDireccionChange = useCallback((e) => {
    const { name, value } = e.target;
    setNuevaDireccion(prev => ({ ...prev, [name]: value }));

    if (name === 'departamento') {
      const provinciasDelDepartamento = [...new Set(ubigeos.filter(u => u.departamento === value).map(u => u.provincia))];
      setProvincias(provinciasDelDepartamento);
      setDistritos([]);
      setNuevaDireccion(prev => ({ ...prev, provincia: '', distrito: '' }));
    } else if (name === 'provincia') {
      const distritosDeProvinciaYDepartamento = [...new Set(ubigeos.filter(u => 
        u.departamento === nuevaDireccion.departamento && u.provincia === value
      ).map(u => u.distrito))];
      setDistritos(distritosDeProvinciaYDepartamento);
      setNuevaDireccion(prev => ({ ...prev, distrito: '' }));
    }
  }, [nuevaDireccion.departamento, ubigeos]);

  const agregarNuevaDireccion = useCallback(async () => {
    try {
      const { data: { id: userId } } = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
      const ubigeoSeleccionado = ubigeos.find(u => 
        u.departamento === nuevaDireccion.departamento && 
        u.provincia === nuevaDireccion.provincia && 
        u.distrito === nuevaDireccion.distrito
      );

      if (!ubigeoSeleccionado?.id_ubigeo) {
        throw new Error('Ubigeo no encontrado o inválido');
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/direcciones/`, {
        usuario: userId,
        calle: nuevaDireccion.calle,
        numero: nuevaDireccion.numero,
        referencia: nuevaDireccion.referencia,
        id_ubigeo: ubigeoSeleccionado.id_ubigeo
      });

      setDirecciones(prev => [...prev, data]);
      setDireccionSeleccionada(data.id_direccion);
      onDireccionSeleccionada(data.id_direccion);
      setMostrarNuevaDireccion(false);
    } catch (error) {
      console.error('Error al agregar nueva dirección:', error);
      // Consider adding user feedback here, e.g., using a toast notification
    }
  }, [nuevaDireccion, ubigeos, onDireccionSeleccionada]);

  const renderInput = (field, label, placeholder) => (
    <div key={field} className="auth-input-group">
      <label className="auth-label" htmlFor={field}>{label}</label>
      <input
        className="auth-input"
        id={field}
        type="text"
        name={field}
        value={nuevaDireccion[field]}
        onChange={handleNuevaDireccionChange}
        required
        placeholder={placeholder}
      />
    </div>
  );

  const renderSelect = (name, options, label) => (
    <div key={name} className="auth-input-group">
      <label className="auth-label" htmlFor={name}>{label}</label>
      <select
        className="auth-input"
        id={name}
        name={name}
        value={nuevaDireccion[name]}
        onChange={handleNuevaDireccionChange}
        required
      >
        <option value="">Seleccione {label.toLowerCase()}</option>
        {options.map((opt) => (
          <option key={opt} value={opt}>{opt}</option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="card">
      <div className="auth-input-group">
        <label className="auth-label" htmlFor="direccion"></label>
        <select
          className="auth-input"
          id="direccion"
          value={direccionSeleccionada}
          onChange={(e) => {
            setDireccionSeleccionada(e.target.value);
            onDireccionSeleccionada(e.target.value);
          }}
          required
        >
          <option value="">Seleccione una dirección</option>
          {direcciones.map(({ id_direccion, calle, numero, id_ubigeo: { distrito } }) => (
            <option key={id_direccion} value={id_direccion}>
              {`${calle} ${numero}, ${distrito}`}
            </option>
          ))}
        </select>
      </div>

      <div className="auth-input-group">
        <button onClick={() => setMostrarNuevaDireccion(!mostrarNuevaDireccion)} className="btn">
          {mostrarNuevaDireccion ? 'Cancelar' : 'Agregar Nueva Dirección'}
        </button>
      </div>

      {mostrarNuevaDireccion && (
        <div>
          {renderInput("calle", "Calle", "Ingrese el nombre de la calle")}
          {renderInput("numero", "Número", "Ingrese el número de la dirección")}
          {renderInput("referencia", "Referencia", "Ingrese una referencia (opcional)")}
          {renderSelect("departamento", departamentos, "Departamento")}
          {renderSelect("provincia", provincias, "Provincia")}
          {renderSelect("distrito", distritos, "Distrito")}

          <div className="auth-input-group">
            <button onClick={agregarNuevaDireccion} className="btn">
              Guardar Nueva Dirección
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DireccionEnvio;