import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

const PULL_STATES = [
  { value: 'draft', label: 'Borrador' },
  { value: 'open', label: 'Abierto' },
  { value: 'close', label: 'Cerrado' },
  { value: 'cancel', label: 'Cancelado' }
];

export default function Administration() {
  const [pulls, setPulls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchPulls();
  }, []);

  const fetchPulls = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/pulls/`);
      if (!response.ok) throw new Error('Error al cargar los pulls');
      const data = await response.json();
      setPulls(data.results);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updatePullState = async (pullId, newState) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/pulls/${pullId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ state: newState })
      });

      if (!response.ok) throw new Error('Error al actualizar el estado');

      setPulls(pulls.map(pull => 
        pull.id_pull === pullId ? { ...pull, state: newState } : pull
      ));

      setSuccessMessage(`Estado actualizado correctamente a ${newState}`);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return (
    <div className="flex items-center justify-center h-48">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestión de Pulls</h1>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md mb-4">
          <AlertCircle className="inline-block h-4 w-4 mr-2" />
          <span>{error}</span>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-md mb-4">
          <CheckCircle className="inline-block h-4 w-4 mr-2" />
          <span>{successMessage}</span>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Producto</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cantidad Actual</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descuento Actual</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tiempo Restante</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {pulls.map((pull) => (
              <tr key={pull.id_pull}>
                <td className="px-6 py-4 whitespace-nowrap">{pull.id_pull}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pull.product?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pull.current_quantity}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pull.current_discount}%</td>
                <td className="px-6 py-4 whitespace-nowrap">{pull.time_left}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={pull.state}
                    onChange={(e) => updatePullState(pull.id_pull, e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {PULL_STATES.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}