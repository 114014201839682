import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DireccionEnvio from './DireccionEnvio';
import { Minus, Plus, Trash2 } from 'lucide-react';
import { AuthContext } from '../App';

const CarritoCompra = () => {
  const { isAuthenticated, checkAuthStatus } = useContext(AuthContext);
  const [orden, setOrden] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState('');
  const navigate = useNavigate();

  const crearNuevaOrden = useCallback(async () => {
    try {
      const { data: { id: customerId } } = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/sales-orders/`, {
        customer: customerId,
        state: 'draft',
        order_type: 'regular',
        salesorderline_set: []
      });
      setOrden(data);
    } catch (error) {
      setError("Error al crear un nuevo carrito");
    }
  }, []);

  const cargarOrden = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-cart/`);
      setOrden(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        checkAuthStatus();
      } else if (error.response?.status === 404) {
        await crearNuevaOrden();
      } else {
        setError("Error al cargar el carrito");
      }
    } finally {
      setLoading(false);
    }
  }, [checkAuthStatus, crearNuevaOrden]);

  const agregarEnvio = useCallback(async (direccionId) => {
    if (!orden) return;
    try {
      const { data: direccion } = await axios.get(`${process.env.REACT_APP_API_URL}/direcciones/${direccionId}/`);
      
      const lineasSinEnvio = orden.salesorderline_set.filter(line => line.product !== 2);
      
      // Si no hay productos (excepto delivery), no agregamos línea de envío
      if (lineasSinEnvio.length === 0) {
        return;
      }

      const nuevaLineaEnvio = {
        product: 2,
        product_name: `DELIVERY TO ${direccion.id_ubigeo.distrito}`,
        product_qty: '1.00',
        price_unit: parseFloat(direccion.id_ubigeo.precio),
        price_subtotal: parseFloat(direccion.id_ubigeo.precio),
        state: 'draft'
      };

      const ordenActualizada = {
        ...orden,
        salesorderline_set: [...lineasSinEnvio, nuevaLineaEnvio]
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/sales-orders/${orden.id_salesorder}/`,
        ordenActualizada
      );
      setOrden(data);
    } catch (error) {
      console.error('Error al agregar envío:', error);
      setError("Error al agregar costo de envío");
      if (error.response?.status === 401) {
        checkAuthStatus();
      }
    }
  }, [orden, checkAuthStatus]);

  const handleDireccionSeleccionada = useCallback((direccionId) => {
    setDireccionSeleccionada(direccionId);
    if (direccionId) {
      agregarEnvio(direccionId);
    }
  }, [agregarEnvio]);

  const actualizarCantidad = useCallback(async (productId, lineId, newQuantity) => {
    if (!orden) return;
    try {
      // Filtrar primero las líneas que no son de envío y tienen cantidad > 0
      const lineasProductos = orden.salesorderline_set
        .filter(line => line.product !== 2)
        .map(line => 
          line.id_salesorderline === lineId 
            ? {...line, product_qty: Math.max(0, newQuantity)} 
            : line
        )
        .filter(line => line.product_qty > 0);

      // Si hay productos, mantener/agregar la línea de envío, si no, eliminarla
      const lineaEnvio = orden.salesorderline_set.find(line => line.product === 2);
      const lineasFinales = lineasProductos.length > 0 && lineaEnvio 
        ? [...lineasProductos, lineaEnvio]
        : lineasProductos;

      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/sales-orders/${orden.id_salesorder}/`, {
        ...orden,
        salesorderline_set: lineasFinales
      });
      setOrden(data);
    } catch (error) {
      console.error('Error al actualizar la orden:', error);
      if (error.response?.status === 401) {
        checkAuthStatus();
      }
    }
  }, [orden, checkAuthStatus]);

  const confirmarOrden = useCallback(async () => {
    if (!orden || !direccionSeleccionada) return;
    try {
      const ordenActualizada = {
        ...orden,
        state: 'sale',
        id_direccion: parseInt(direccionSeleccionada),
        salesorderline_set: orden.salesorderline_set.map(line => ({
          ...line,
          state: 'registered'
        }))
      };
      
      await axios.put(`${process.env.REACT_APP_API_URL}/sales-orders/${orden.id_salesorder}/`, ordenActualizada);
      setOrden(null);
      alert('Orden confirmada con éxito');
      navigate('/mis-pedidos');
    } catch (error) {
      console.error('Error al confirmar la orden:', error);
      setError("Error al confirmar la orden. Por favor, intente de nuevo.");
      if (error.response?.status === 401) {
        checkAuthStatus();
      }
    }
  }, [orden, direccionSeleccionada, navigate, checkAuthStatus]);

  useEffect(() => {
    if (isAuthenticated) {
      cargarOrden();
    }
  }, [isAuthenticated, cargarOrden]);

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!orden || orden.salesorderline_set.length === 0) return <div className="text-center mt-10">No hay productos en el carrito</div>;

  return (
    <div className="page-container">
      <h2 className="page-title">Carrito de Compra</h2>
      
      <div className="card">
        <h3 className="text-center mb-4">Orden: {orden.name}</h3>
        
        {orden.salesorderline_set.map((item) => (
          <div key={item.id_salesorderline} className="item">
            <div className="item-name" title={item.product_name}>
              {item.product_name}
            </div>
            
            {item.product !== 2 && (
              <div className="flex items-center">
                <button 
                  className="btn p-1"
                  onClick={() => actualizarCantidad(item.product, item.id_salesorderline, parseInt(item.product_qty) - 1)}
                >
                  <Minus size={16} />
                </button>
                <span className="mx-2">{parseInt(item.product_qty)}</span>
                <button 
                  className="btn p-1"
                  onClick={() => actualizarCantidad(item.product, item.id_salesorderline, parseInt(item.product_qty) + 1)}
                >
                  <Plus size={16} />
                </button>
              </div>
            )}

            <div className="item-subtotal">
              <span>S/{parseFloat(item.price_subtotal).toFixed(2)}</span>
              {item.product !== 2 && (
                <span className="text-sm line-through text-gray-500 ml-2">
                  S/{parseFloat(item.price_unit * item.product_qty).toFixed(2)}
                </span>
              )}
            </div>

            {item.product !== 2 && (
              <button 
                onClick={() => actualizarCantidad(item.product, item.id_salesorderline, 0)} 
                className="btn p-1"
              >
                <Trash2 size={16} />
              </button>
            )}
          </div>
        ))}

        <p className="total">
          Total: S/{parseFloat(orden.amount_total).toFixed(2)}
        </p>
      </div>

      <div className="address-card">
        <h3 className="address-title">Dirección de Envío</h3>
        <DireccionEnvio onDireccionSeleccionada={handleDireccionSeleccionada} />
      </div>

      <div className="confirm-container">
        <button 
          onClick={confirmarOrden}
          disabled={!direccionSeleccionada}
          className="btn confirm-btn"
        >
          Confirmar Orden
        </button>
      </div>
    </div>
  );
};

export default CarritoCompra;