import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

const RegistroUsuario = () => {
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    phone: '',
    countrycode: '',
    first_name: '',
    last_name: '',
  });
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [imageError, setImageError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateImage = (file) => {
    const maxSize = 5 * 1024 * 1024; // 5MB en bytes
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    
    if (!allowedTypes.includes(file.type)) {
      setImageError('Por favor, sube una imagen en formato JPG, PNG o WebP');
      return false;
    }
    
    if (file.size > maxSize) {
      setImageError('La imagen no debe superar los 5MB');
      return false;
    }
    
    setImageError('');
    return true;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (validateImage(file)) {
        setSelectedImage(file);
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
      } else {
        e.target.value = '';
        setSelectedImage(null);
        setPreviewUrl('');
      }
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'countrycode') {
      if (/^\+?\d*$/.test(value) && value.length <= 4) {
        setFormData({ ...formData, countrycode: value });
      }
    } else if (name === 'phone') {
      if (/^\d*$/.test(value) && value.length <= 9) {
        setFormData({ ...formData, phone: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (selectedImage && !validateImage(selectedImage)) {
      return;
    }

    const submitFormData = new FormData();
    Object.keys(formData).forEach(key => {
      submitFormData.append(key, formData[key]);
    });
    
    if (selectedImage) {
      submitFormData.append('img', selectedImage);
    }

    try {
      // Primero, realizar el registro
      const registroResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/registro/`, 
        submitFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Si el registro es exitoso, realizar el login automáticamente
      if (registroResponse.data) {
        const loginData = {
          username: formData.username,
          password: formData.password
        };

        const loginResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/login/`,
          loginData
        );

        if (loginResponse.data.access) {
          login(loginResponse.data.access, loginResponse.data.refresh);
          
          try {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/user/`,
              {
                headers: {
                  'Authorization': `Bearer ${loginResponse.data.access}`
                }
              }
            );
            localStorage.setItem('username', userResponse.data.username);
          } catch (userError) {
            console.error('Error fetching user data:', userError);
          }

          navigate('/');
        }
      }
    } catch (error) {
      console.error('Error completo:', error);
      setError(error.response?.data?.error || 'Ocurrió un error durante el registro');
    }
  };

  return (
    <div className="page-container">
      <h2 className="page-title">Registro de Usuario</h2>
      <div className="card">
        <form onSubmit={handleSubmit} className="auth-form">
          {error && <p className="auth-error">{error}</p>}
          
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="username">Username</label>
            <input
              className="auth-input"
              id="username"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              placeholder="Ingrese su nombre de usuario"
            />
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="first_name">Nombres</label>
            <input
              className="auth-input"
              id="first_name"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              placeholder="Ingrese sus nombres"
            />
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="last_name">Apellidos</label>
            <input
              className="auth-input"
              id="last_name"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              placeholder="Ingrese sus apellidos"
            />
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="email">Correo electrónico</label>
            <input
              className="auth-input"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="ejemplo@correo.com"
            />
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="phone">Celular</label>
            <div className="auth-phone-group">
              <input
                className="auth-input"
                id="countrycode"
                type="text"
                name="countrycode"
                placeholder="+51"
                value={formData.countrycode}
                onChange={handlePhoneChange}
                maxLength="4"
                required
                style={{ width: '80px' }}
              />
              <input
                className="auth-input"
                id="phone"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handlePhoneChange}
                maxLength="9"
                required
                placeholder="999999999"
                style={{ flex: 1 }}
              />
            </div>
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="password">Contraseña</label>
            <input
              className="auth-input"
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Ingrese su contraseña"
            />
          </div>

          <div className="auth-input-group">
            <label className="auth-label" htmlFor="img">Imagen de perfil (opcional)</label>
            <input
              className="auth-input"
              id="img"
              type="file"
              name="img"
              accept="image/jpeg,image/png,image/webp"
              onChange={handleImageChange}
            />
            {imageError && (
              <p className="auth-error">{imageError}</p>
            )}
            {previewUrl && (
              <div className="image-preview">
                <img 
                  src={previewUrl} 
                  alt="Vista previa" 
                  style={{ 
                    maxWidth: '200px', 
                    marginTop: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                />
              </div>
            )}
          </div>

          <button 
            className="btn" 
            type="submit"
          >
            Registrarse
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistroUsuario;