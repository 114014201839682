import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext, PaymentContext } from '../App';
import KRGlue from '@lyracom/embedded-form-glue';
import { CheckIcon } from 'lucide-react';

const OrderStepper = ({ currentState = 'registered', isDelivery }) => {
  const steps = isDelivery ? [
    { id: 'registered', label: 'Registrado' },
    { id: 'paid', label: 'Pagado' },
    { id: 'sent', label: 'En reparto' },
    { id: 'done', label: 'Entregado' }
  ] : [
    { id: 'registered', label: 'Registrado' },
    { id: 'paid', label: 'Pagado' },
    { id: 'sent', label: 'Enviado' },
    { id: 'done', label: 'Completado' }
  ];

  const currentIndex = steps.findIndex(step => step.id === currentState);

  const getStepStatus = (stepId) => {
    const stepIndex = steps.findIndex(step => step.id === stepId);
    if (stepIndex < currentIndex) return 'completed';
    if (stepIndex === currentIndex) return 'active';
    return 'pending';
  };

  return (
    <div className="w-full py-4">
      <div className="flex justify-between">
        {steps.map((step, index) => {
          const status = getStepStatus(step.id);
          return (
            <div key={step.id} className="flex flex-col items-center">
              <div
                className={`
                  w-8 h-8 rounded-full flex items-center justify-center 
                  transition-all duration-300 border-2
                  ${
                    status === 'completed' || status === 'active'
                      ? 'border-[var(--primary-color)] text-[var(--primary-color)]'
                      : 'border-[var(--border-color)] text-[var(--text-secondary)]'
                  }
                `}
              >
                {status === 'completed' ? (
                  <CheckIcon className="w-4 h-4" />
                ) : (
                  <span className="text-sm font-medium">{index + 1}</span>
                )}
              </div>
              <span
                className={`
                  mt-2 text-xs font-medium transition-colors duration-300
                  ${
                    status === 'active'
                      ? 'text-[var(--primary-color)]'
                      : 'text-[var(--text-secondary)]'
                  }
                `}
              >
                {step.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MisPedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLines, setSelectedLines] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [formToken, setFormToken] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [pullStates, setPullStates] = useState({});

  const navigate = useNavigate();
  const { checkAuthStatus } = useContext(AuthContext);
  const { publicKey, endPoint, getFormToken, validatePayment } = useContext(PaymentContext);

  const fetchPullState = useCallback(async (pullId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pulls-detail/${pullId}/`);
      setPullStates(prev => ({
        ...prev,
        [pullId]: response.data.state
      }));
    } catch (error) {
      console.error('Error fetching pull state:', error);
    }
  }, []);

  const updateSalesOrderLines = useCallback(async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/update-salesorderlines/`);
    } catch (error) {
      console.error('Error al actualizar SalesOrderLines:', error);
    }
  }, []);

  const cargarPedidos = useCallback(async (page) => {
    try {
      setLoading(true);
      await updateSalesOrderLines();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales-orders/?page=${page}&state=sale,done,cancel`);
      setPedidos(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      if (error.response?.status === 401) {
        checkAuthStatus();
      } else {
        setError("Error al cargar los pedidos");
      }
    } finally {
      setLoading(false);
    }
  }, [checkAuthStatus, updateSalesOrderLines]);

  useEffect(() => {
    cargarPedidos(currentPage);
  }, [currentPage, cargarPedidos]);

  useEffect(() => {
    pedidos.forEach(pedido => {
      pedido.salesorderline_set.forEach(item => {
        if (item.pull) {
          fetchPullState(item.pull);
        }
      });
    });
  }, [pedidos, fetchPullState]);

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleString('es-ES', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      : 'Fecha no válida';
  }, []);

  const handleLineSelection = useCallback((line) => {
    setSelectedLines(prev => {
      const isSelected = prev.some(l => l.id_salesorderline === line.id_salesorderline);
      const newLines = isSelected
        ? prev.filter(l => l.id_salesorderline !== line.id_salesorderline)
        : [...prev, line];
      
      const total = newLines.reduce((sum, l) => sum + parseFloat(l.price_subtotal), 0);
      setTotalAmount(total);
      return newLines;
    });
  }, []);

  const handleSelectAllOrder = useCallback((pedido) => {
    const eligibleLines = pedido.salesorderline_set.filter(item => 
      item.state === 'registered' && (
        item.product === 2 || // Delivery items
        (item.pull && pullStates[item.pull] === 'close') // Non-delivery items with closed pull
      )
    );

    setSelectedLines(prev => {
      const allSelected = eligibleLines.every(line => 
        prev.some(l => l.id_salesorderline === line.id_salesorderline)
      );

      const newLines = allSelected
        ? prev.filter(l => !eligibleLines.some(el => el.id_salesorderline === l.id_salesorderline))
        : [...prev, ...eligibleLines.filter(line => 
            !prev.some(l => l.id_salesorderline === line.id_salesorderline)
          )];

      const total = newLines.reduce((sum, l) => sum + parseFloat(l.price_subtotal), 0);
      setTotalAmount(total);
      return newLines;
    });
  }, [pullStates]);

  const handlePayment = async () => {
    try {
      const orderLines = selectedLines.map(line => ({
        salesorderline_id: line.id_salesorderline,
        amount: parseFloat(line.price_subtotal)
      }));
      
      const token = await getFormToken(totalAmount, orderLines);
      setFormToken(token);
      setShowPaymentForm(true);
      setPaymentError(null);
  
      const { KR } = await KRGlue.loadLibrary(endPoint, publicKey);
      await KR.setFormConfig({
        formToken: token,
        "kr-language": "es-ES"
      });
  
      await KR.onSubmit(async (paymentData) => {
        try {
          const isPaid = await validatePayment(paymentData);
          if (isPaid) {
            alert("Pago realizado con éxito");
            window.location.reload();
          } else {
            setPaymentError("El pago no se completó correctamente");
            window.location.reload();
          }
        } catch (error) {
          setPaymentError("Error al procesar el pago: " + 
            (error?.response?.data?.error || error?.message || 'Error desconocido'));
          window.location.reload();
        }
      });
  
      await KR.renderElements("#myPaymentForm");
      await KR.showForm(token);
    } catch (error) {
      setPaymentError('Error al iniciar el proceso de pago: ' + 
        (error?.response?.data?.error || error?.message || 'Error desconocido'));
      window.location.reload();
    }
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10">{error}</div>;
  if (pedidos.length === 0) return <div className="text-center mt-10">No tienes pedidos confirmados</div>;

  return (
    <div className="page-container px-4 py-8">
      <h2 className="page-title mb-8">Mis Pedidos</h2>
      
      <div className="grid gap-8">
      {pedidos.map((pedido) => (
        <div key={pedido.id_salesorder} className="card">
          <h3 className="text-center mb-4">Orden: {pedido.name}</h3>
          <p className="text-sm text-gray-400 mb-2">Fecha: {formatDate(pedido.date_order)}</p>
          
          <div className="flex items-center px-4 mb-4">
            <input
              type="checkbox"
              checked={pedido.salesorderline_set.every(line => 
                (line.state === 'registered' && (line.product === 2 || (line.pull && pullStates[line.pull] === 'close'))) ?
                selectedLines.some(l => l.id_salesorderline === line.id_salesorderline) :
                true
              )}
              onChange={() => handleSelectAllOrder(pedido)}
              className="h-4 w-4"
            />
            <span className="text-sm text-text-secondary ml-3">Seleccionar todo</span>
          </div>
          
          {pedido.salesorderline_set.map((item) => (
              <div key={item.id_salesorderline} className="border-b border-border-color last:border-b-0 py-4 px-4">
                <div className="flex items-center gap-3 mb-3">
                  <input
                    type="checkbox"
                    checked={selectedLines.some(l => l.id_salesorderline === item.id_salesorderline)}
                    onChange={() => handleLineSelection(item)}
                    disabled={item.state !== 'registered' || (!item.product === 2 && !(item.pull && pullStates[item.pull] === 'close'))}
                    className="h-4 w-4"
                  />
                  <div className="item-name font-semibold text-text-color">
                    {item.product_name}
                  </div>
                </div>
                
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center bg-card-bg-color rounded-md px-3 py-1">
                    <span className="text-text-secondary">Cantidad:</span>
                    <span className="ml-2 font-semibold">{parseInt(item.product_qty)}</span>
                  </div>
                  <div className="flex items-center bg-card-bg-color rounded-md px-3 py-1">
                    <span className="text-text-secondary">Subtotal:</span>
                    <span className="ml-2 font-semibold">S/{parseFloat(item.price_subtotal).toFixed(2)}</span>
                  </div>
                </div>

                <OrderStepper 
                  currentState={item.state} 
                  isDelivery={item.product === 2} 
                />
              </div>
            ))}

            <p className="total px-4">
              Total: S/{parseFloat(pedido.amount_total).toFixed(2)}
            </p>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`btn mx-1 ${currentPage === page ? 'bg-primary-color' : ''}`}
          >
            {page}
          </button>
        ))}
      </div>

      {selectedLines.length > 0 && (
        <div className="confirm-container">
          <h3 className="text-lg font-semibold mb-2 text-center">
            Total a pagar: S/{totalAmount.toFixed(2)}
          </h3>
          <button onClick={handlePayment} className="btn confirm-btn">
            Pagar líneas seleccionadas
          </button>
        </div>
      )}

      {showPaymentForm && formToken && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-card-bg-color p-6 rounded-lg max-w-md w-full relative">
            <button 
              onClick={() => {
                setShowPaymentForm(false);
                window.location.reload();
              }}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <h3 className="text-lg font-semibold mb-4">Formulario de Pago</h3>
            <div id="myPaymentForm">
              <div className="kr-embedded"></div>
            </div>
          </div>
        </div>
      )}
      
      {paymentError && (
        <div className="error-message mt-4 text-red-500">
          {paymentError}
        </div>
      )}
    </div>
  );
};

export default MisPedidos;